export const processStatusStyles = (theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    margin: 0,
    marginTop: theme.spacing(4),
    backgroundColor: theme.palette.background.default,
  },
  title: {
    marginTop: theme.spacing(2),
  },
  imageContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(4),
    width: '100%',
    maxWidth: '400px',
    height: 'auto',
  },
  dynamicImage: {
    width: '100%',
    height: 'auto',
    objectFit: 'contain',
  },
})
