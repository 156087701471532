import { useCallback } from 'react'
import { useDispatch } from 'react-redux'

import { setAuthData, setLocale } from '../slices'
import { removeAllLocalStorage, saveLocalStorage } from '../../../core/utils'
import { requests } from '../../../core/ajax'
import { setUserData } from '../../profile/slices/profile.slice'

export const useGlobalActions = () => {
  const dispatch = useDispatch()

  const loginUser = useCallback(
    (data) =>
      new Promise(async (resolve, reject) => {
        const payload = {
          username: data.username,
          password: data.password,
        }
        return await requests
          .login(payload)
          .then(async (response) => {
            saveLocalStorage('userData', response.data)
            try {
              const user_response = await getUserInfo()
              dispatch(setAuthData({ ...response.data, isAuthorized: true }))
              return resolve(user_response)
            } catch (reason) {
              return reject(reason)
            }
          })
          .catch((reason) => {
            removeAllLocalStorage()
            return reject(reason)
          })
      }),
    [] // eslint-disable-line
  )

  const getUserInfo = useCallback(
    async () =>
      await new Promise(async (resolve, reject) => {
        await requests
          .infoClient()
          .then((response) => {
            dispatch(setUserData(response.data))
            return resolve(response)
          })
          .catch(() => reject())
      }),
    []
  )

  const logoutUser = useCallback(() => {
    removeAllLocalStorage()
    dispatch(setAuthData({ isAuthorized: false }))
  }, []) // eslint-disable-line

  const changeLanguage = (language) => {
    dispatch(setLocale(language))
    saveLocalStorage('language', language)
  }

  return {
    loginUser,
    logoutUser,
    changeLanguage,
  }
}
