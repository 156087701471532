import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'

import { LOCALES } from './constants'
import { useSelector, useDispatch } from 'react-redux'
import { setLocale } from '../../features/global/slices'
import { loadLocalStorage } from '../../core/utils'

export const I18NProvider = ({ children }) => {
  const dispatch = useDispatch()

  const language = useSelector((state) => state.global.locale)

  useEffect(() => {
    const langFromStorage = loadLocalStorage('language')
    if (langFromStorage) dispatch(setLocale(langFromStorage))
  }, [])

  return (
    <IntlProvider locale={language} messages={LOCALES[language]} defaultLocale={'es_ES'}>
      {children}
    </IntlProvider>
  )
}
