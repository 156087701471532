import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Icon, Typography } from '@material-ui/core'
import { toast } from 'react-toastify'

import { CodeInput, PasswordInput, TextInput, ThemeButton } from '../../../../ui'
import { verifyPwd } from '../../../../core/utils'
import { useLoginCalls } from '../../hooks/login-calls.hook'

export const PwdRecoveryDialogComponent = ({ close }) => {
  const { formatMessage } = useIntl()
  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [step, setStep] = useState(1)

  const { sendRecoveryCode, confirmRecovery } = useLoginCalls()

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSendCode = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)
    if (step === 1) {
      if (!formState['username']) {
        setErrorMssg(
          formatMessage({
            id: 'pages.login.pwdRecovery.error.email.required',
            defaultMessage: 'El campo de correo es obligatorio',
          })
        )
        setLoading(false)
        return
      }
      const payload = { username: formState['username'] }
      sendRecoveryCode(payload)
        .then(() => {
          toast.success(
            <>
              <Icon>check_circle</Icon>
              <Typography
                variant="body1"
                display="inline"
                style={{ verticalAlign: 'super', marginLeft: 8 }}
              >
                {formatMessage({ id: 'pages.login.pwdRecovery.code.success' })}
              </Typography>
            </>
          )
          setLoading(false)
          setStep(2)
        })
        .catch(() => {
          setLoading(false)
          setErrorMssg(
            formatMessage({
              id: 'pages.login.pwdRecovery.error.email.invalid',
              defaultMessage: 'El correo introducido no es válido',
            })
          )
        })
    } else {
      if (!formState['code'] || !formState['password'] || !formState['repeatPassword']) {
        setErrorMssg(
          formatMessage({
            id: 'pages.login.pwdRecovery.error.fields.required',
            defaultMessage: 'Todos los campos son obligatorios',
          })
        )
        setLoading(false)
        return
      }
      if (formState['password'] !== formState['repeatPassword']) {
        setErrorMssg(
          formatMessage({
            id: 'pages.login.pwdRecovery.error.password.mismatch',
            defaultMessage: 'Las contraseñas no coinciden',
          })
        )
        setLoading(false)
        return
      }
      if (!verifyPwd(formState['password'], formState['repeatPassword'], formatMessage).isValid) {
        setErrorMssg(
          formatMessage({
            id: 'pages.login.pwdRecovery.error.password.insecure',
            defaultMessage:
              'La contraseña debe tener al menos 8 caracteres, una letra mayúscula, una minúscula, un número y un carácter especial',
          })
        )
        setLoading(false)
        return
      }
      const payload = {
        username: formState['username'],
        code: formState['code'],
        new_password: formState['password'],
      }
      confirmRecovery(payload)
        .then(() => {
          toast.success(
            <>
              <Icon>check_circle</Icon>
              <Typography
                variant="body1"
                display="inline"
                style={{ verticalAlign: 'super', marginLeft: 8 }}
              >
                {formatMessage({ id: 'pages.login.pwdRecovery.feedback.success' })}
              </Typography>
            </>
          )
          setLoading(false)
          close()
        })
        .catch(() => {
          setLoading(false)
          setErrorMssg(
            formatMessage({
              id: 'pages.login.pwdRecovery.error.password.changeFailed',
              defaultMessage: 'No se ha podido cambiar la contraseña',
            })
          )
        })
    }
  }

  return (
    <Box p={4}>
      <Box mb={4} textAlign="center">
        <Typography variant="h5" color="primary">
          {formatMessage({ id: 'pages.login.pwdRecovery.title' })}
        </Typography>
      </Box>
      <form onSubmit={handleSendCode}>
        {step === 1 ? (
          <Box mb={2} display={'flex'} flexDirection={'column'} gridGap={'16px'}>
            <TextInput
              type="text"
              required
              value={formState['username']}
              name="username"
              title={formatMessage({ id: 'pages.login.pwdRecovery.form.email.label' })}
              placeholder={formatMessage({ id: 'pages.login.pwdRecovery.form.email.placeholder' })}
              onChange={handleChangeForm}
              helperText={formatMessage({ id: 'pages.login.pwdRecovery.form.message1' })}
            />
          </Box>
        ) : (
          <Box mb={2} display={'flex'} flexDirection={'column'} gridGap={'16px'}>
            <Typography variant="body1" color="primary">
              {formatMessage({ id: 'pages.login.pwdRecovery.form.help' })}
            </Typography>
            <CodeInput
              label={formatMessage({
                id: 'pages.profile.user.fields.code',
                defaultMessage: 'Código',
              })}
              name="code"
              onChange={handleChangeForm}
            />
            <PasswordInput
              value={formState['password']}
              name="password"
              title={formatMessage({ id: 'pages.signup.form.password.title' })}
              onChange={handleChangeForm}
              required
              autoComplete="new-password"
            />
            <Box bgcolor="grey">
              <Typography
                variant="caption"
                color="textSecondary"
                style={{ whiteSpace: 'pre-line' }}
              >
                {formatMessage({ id: 'pages.pwdRecovery.extra' })}
              </Typography>
            </Box>
            <PasswordInput
              value={formState['repeatPassword']}
              name="repeatPassword"
              title={formatMessage({ id: 'pages.signup.form.repitPassword.title' })}
              placeholder={formatMessage({ id: 'pages.signup.form.repitPassword.placeholder' })}
              onChange={handleChangeForm}
              required
            />
          </Box>
        )}

        {errorMssg && (
          <Box mb={1} bgcolor="grey">
            <Typography variant="body2" color="error">
              {errorMssg}
            </Typography>
          </Box>
        )}
        <ThemeButton type="submit" fullWidth loading={loading} disabled={loading}>
          {formatMessage({ id: 'pages.login.pwdRecovery.form.button.1.label' })}
        </ThemeButton>
        <ThemeButton
          variant="outlined"
          fullWidth
          loading={loading}
          disabled={loading}
          onClick={close}
        >
          {formatMessage({ id: 'pages.login.pwdRecovery.form.button.2.label' })}
        </ThemeButton>
      </form>
    </Box>
  )
}
