import React, { useEffect, useState } from 'react'
import { Redirect, Route, Switch, withRouter } from 'react-router-dom'
import { Box, CircularProgress } from '@material-ui/core'

import { PrivateLayoutComponent } from '../../ui'
import { Profile } from '../../pages'
import { InvoicesPage } from '../../pages/invoices'
import { ContractsPage } from '../../pages/contracts'
import { ProcessStatusPage } from '../../pages/process-status'
import { setUserData } from '../../features/profile/slices/profile.slice'
import { useDispatch } from 'react-redux'
import { loadLocalStorage } from '../../core/utils'

const BarePrivateRouter = () => {
  const [loadingUser, setLoadingUser] = useState(true)

  const userInfo = loadLocalStorage('user')

  const dispatch = useDispatch()

  useEffect(() => {
    setLoadingUser(false)
    dispatch(setUserData(userInfo))
  }, []) // eslint-disable-line

  return (
    <PrivateLayoutComponent>
      {loadingUser ? (
        <Box
          display="flex"
          height="100%"
          width={'100%'}
          justifyContent="center"
          alignItems="center"
        >
          <CircularProgress size={40} />
        </Box>
      ) : (
        <Switch>
          <Route path="/invoices" component={InvoicesPage} />
          <Route path="/contracts" component={ContractsPage} />
          <Route path="/account" component={Profile} />
          <Route path="/process/:status" component={ProcessStatusPage} />
          <Redirect from="*" to="/invoices" />
        </Switch>
      )}
    </PrivateLayoutComponent>
  )
}

export const PrivateRouter = withRouter(BarePrivateRouter)
