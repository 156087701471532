export const invoicesStyles = (theme) => ({
  root: {
    height: '100%',
  },
  container: {
    height: '100%',
    alignItems: 'center',
    padding: '60px 20px',
    [theme.breakpoints.down('xs')]: {
      padding: '40px 20px',
    },
  },
  titleWrapper: {
    marginBottom: 40,
  },
  tabs: {
    // backgroundColor: 'red',
    '& .MuiTab-root': {
      backgroundColor: 'rgba(99, 102, 106, 0.1)',
    },
    '& .Mui-selected': {
      backgroundColor: '#004165',
      color: 'white',
    },
  },
  table: {
    '& .Mui-checked': {
      color: '#f6bf00 !important',
    },
    '& .MuiCheckbox-root.Mui-disabled': {
      color: 'rgba(0, 0, 0, 0.26) !important',
      display: 'none',
    },
    '& .MuiCheckbox-root': {
      paddingRight: 0,
    },
    '& .MuiTableBody-root': {
      '& .MuiTableCell-root': {
        padding: '0 8px !important',
      },
    },
  },
  pendingTableWithSelection: {
    '& .MuiTableHead-root, & .MuiTableBody-root': {
      '& .MuiTableCell-root:first-child': {
        padding: '0 !important',
        width: '30px !important',
      },
    },
  },
  tabIndicator: {
    backgroundColor: 'transparent',
  },
})
