import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { CodeInput, ThemeButton } from '../../../ui'
import { feedback } from '../../../core/feedback'
import { useTwoFactorCalls } from '../hooks/two-factor.hook'
import { Alert } from '@material-ui/lab'

export const UnblurInfoFormComponent = ({ close }) => {
  const { formatMessage } = useIntl()
  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const { verifyCode, sendCode } = useTwoFactorCalls()

  const handleChangeForm = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  useEffect(() => {
    sendCode().then((response) => {
      console.log('Response:', response)
    })
  }, [])

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)

    await verifyCode(formState.code)
      .then((response) => {
        if (response?.token) {
          feedback(
            'success',
            formatMessage({
              id: 'pages.profile.success.validCode',
              defaultMessage: 'Código válido',
            })
          )
          close()
        } else {
          setErrorMssg(
            formatMessage({
              id: 'pages.profile.error.invalidCode',
              defaultMessage: 'El código introducido es incorrecto',
            })
          )
        }
        setLoading(false)
      })
      .catch((error) => {
        console.log('error', error)
        if (error) {
          setErrorMssg(
            formatMessage({
              id: 'pages.profile.error.invalidCode',
              defaultMessage: 'El código es incorrecto',
            })
          )
          setLoading(false)
        }
      })
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={1} display={'flex'} justifyContent={'center'}>
        {/*<TextField
          value={formState['code'] || ''}
          name="code"
          label={formatMessage({ id: 'pages.profile.user.fields.code', defaultMessage: 'Código' })}
          placeholder={formatMessage({ id: 'pages.profile.edit.fields.code.placeholder', defaultMessage: 'Ingresa el código' })}
          onChange={handleChangeForm}
          fullWidth
          variant="outlined"
        /> */}
        <CodeInput
          label={formatMessage({ id: 'pages.profile.user.fields.code', defaultMessage: 'Código' })}
          name="code"
          onChange={handleChangeForm}
        />
      </Box>
      {errorMssg && (
        <Box mb={1} bgcolor="grey">
          <Alert severity={'error'}>{errorMssg}</Alert>
        </Box>
      )}
      <Box display="flex" justifyContent="center" mt={2}>
        <ThemeButton
          type="submit"
          style={{ marginRight: '50px' }}
          loading={loading}
          disabled={!(formState['code'] && formState['code'].length === 6)}
        >
          {formatMessage({ id: 'global.accept', defaultMessage: 'Aceptar' })}
        </ThemeButton>
        <ThemeButton color="default" onClick={close}>
          {formatMessage({ id: 'global.cancel', defaultMessage: 'Cancelar' })}
        </ThemeButton>
      </Box>
    </form>
  )
}

export default UnblurInfoFormComponent
