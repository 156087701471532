import React, { useState } from 'react'
import { useIntl } from 'react-intl'
import { Box } from '@material-ui/core'
import { useSignUpCalls } from '../../hooks/signup.hook'
import { useHistory } from 'react-router-dom'
import { CodeInput, ThemeButton } from '../../../../ui'
import { useGlobalActions } from '../../../global'
import { Alert } from '@material-ui/lab'

export const ConfirmSignupFormComponent = ({ username, password, email, close }) => {
  const { formatMessage } = useIntl()
  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')

  const { loginUser } = useGlobalActions()

  const history = useHistory()

  const { verifyCode } = useSignUpCalls()

  const handleChangeForm = (event) => {
    setFormState({ ...formState, [event.target.name]: event.target.value })
  }

  const onLogin = async () => {
    const payload = {
      username: username,
      password: password,
    }
    await loginUser(payload)
      .then(() => {
        setLoading(false)
        close()
        history.push('/invoices')
      })
      .catch((reason) => {
        if (reason?.response?.status === 401) {
          setErrorMssg('inactive_user')
        } else {
          setErrorMssg('Usuario o contraseña incorrecta')
        }
        setLoading(false)
      })
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)

    verifyCode(username, formState.code)
      .then((response) => {
        if (response.status === 201) {
          onLogin()
        } else if (response.status === 409) {
          setErrorMssg(
            formatMessage({
              id: 'pages.signup.confirmDialog.errors.expiratedCode',
              defaultMessage: 'El código ha expirado',
            })
          )
          setLoading(false)
        } else {
          setErrorMssg(
            formatMessage({
              id: 'pages.profile.error.invalidCode',
              defaultMessage: 'El código es incorrecto',
            })
          )
          setLoading(false)
        }
        setLoading(false)
      })
      .catch((error) => {
        switch (error?.response?.status) {
          case 409:
            setErrorMssg(
              formatMessage({
                id: 'pages.signup.confirmDialog.errors.expiratedCode',
                defaultMessage: 'El código ha expirado. Se ha enviado un nuevo código a su correo.',
              })
            )
            break
          case 410:
            setErrorMssg(
              formatMessage({
                id: 'pages.signup.confirmDialog.errors.expiratedCode',
                defaultMessage: 'El código ha expirado. Se ha enviado un nuevo código a su correo.',
              })
            )
            break
          default:
            setErrorMssg(
              formatMessage({
                id: 'pages.profile.error.invalidCode',
                defaultMessage: 'El código es incorrecto',
              })
            )
            break
        }
        setErrorMssg(
          formatMessage({
            id: 'pages.profile.error.invalidCode',
            defaultMessage: 'El código es incorrecto',
          })
        )
        setLoading(false)
      })
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={1} display={'flex'} justifyContent={'center'}>
        <CodeInput
          label={`${formatMessage({
            id: 'pages.profile.user.fields.code',
            defaultMessage: 'Código',
          })} ${email ?? ''}`}
          name="code"
          onChange={handleChangeForm}
        />
      </Box>
      {errorMssg && (
        <Box mb={1} bgcolor="grey" display={'flex'} justifyContent={'center'}>
          <Alert severity={'error'}>{errorMssg}</Alert>
        </Box>
      )}
      <Box display="flex" justifyContent="center" mt={2}>
        <ThemeButton
          type="submit"
          style={{ marginRight: '50px' }}
          loading={loading}
          disabled={!(formState['code'] && formState['code'].length === 6)}
        >
          {formatMessage({ id: 'global.accept', defaultMessage: 'Aceptar' })}
        </ThemeButton>
      </Box>
    </form>
  )
}
