import React, { useContext, useEffect, useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import Dialog from '@material-ui/core/Dialog'
import Typography from '@material-ui/core/Typography'
import { Box, Grid, withStyles, Icon } from '@material-ui/core'
import MuiDialogActions from '@material-ui/core/DialogActions'
import MuiDialogContent from '@material-ui/core/DialogContent'
import MuiDialogTitle from '@material-ui/core/DialogTitle'
import CloseIcon from '@material-ui/icons/Close'
import { ThemeButton } from '../../../../ui'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { selectRole } from '../../../profile/slices'
import { selectClient } from '../../../searchUser/slices/searchUser.slice'
import { useInvoicesCalls } from '../../hooks/invoices-calls.hook'
import { UnblurInformationDialog } from '../../../two-factor/components/two-factor-dialog.component'
import { ModalContext } from '../../../../ui/modal/contexts'

const downloadPDF = (pdf, name) => {
  const linkSource = `data:application/pdf;base64,${pdf}`
  const downloadLink = document.createElement('a')
  const fileName = name || 'receipt.pdf'
  downloadLink.href = linkSource
  downloadLink.download = fileName
  downloadLink.click()
}

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: '2px',
    color: theme.palette.grey[500],
  },
  headerText: {
    display: 'flex',
    justifyContent: 'space-between',
    marginRight: '100px',
    gap: '2em',
  },
  headerImporte: {
    fontSize: '14px',
    fontWeight: 'bold',
    color: '#004165',
  },
})

const DialogTitle = withStyles(styles)((props) => {
  const { importe, children, classes, onClose, ...other } = props
  const intl = useIntl()
  const { formatMessage } = intl

  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Box className={classes.headerText}>
        <Typography variant="h6" color={'primary'}>
          {children}
        </Typography>
        <Typography variant={'body1'}>
          {formatMessage({ id: 'pages.invoice.details.amount', defaultMessage: 'Importe' })}:{' '}
          <span className={classes.headerImporte}>
            {new Intl.NumberFormat('es-ES', {
              style: 'currency',
              currency: 'EUR',
            }).format(importe)}
          </span>
        </Typography>
      </Box>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  )
})

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent)

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
    justifyContent: 'center',
  },
}))(MuiDialogActions)

export const InvoicesDetailComponent = ({ invoice, onCloseDetail, showDownloadPayment }) => {
  const [openDialog, setOpenDialog] = useState(false)
  const [pendingAction, setPendingAction] = useState(null)
  const isTwoFactorAuthenticated = useSelector(
    (state) => state.global.authData.isTwoFactorAuthenticated
  ) // Estado de 2FA desde Redux
  const intl = useIntl()
  const { formatMessage } = intl
  const {
    actions: { open },
  } = useContext(ModalContext)
  const role = useSelector(selectRole)
  const clientInfo = useSelector(selectClient)

  const [isLoadingPdf, setIsLoadingPdf] = useState(false)

  const { getInvoicePdf, getInvoicePaymentPdf } = useInvoicesCalls()

  const handleClose = () => {
    onCloseDetail()
    setOpenDialog(false)
  }

  const handleOpenUnblurDialog = () =>
    open({
      Component: UnblurInformationDialog,
      props: {
        close: handleClose(),
      },
      type: 'responsive',
    })

  const downloadInvoice = () => {
    setIsLoadingPdf(true)
    const config = { params: { invoice: invoice.factura } }
    if (role === 'ATR' && clientInfo['nombre']) {
      config.params['client'] = clientInfo['dni']
    }
    getInvoicePdf(config)
      .then((response) => {
        if (response.data.contenido) {
          if (window.navigator.appVersion.toString().indexOf('.NET') > 0) {
            var byteCharacters = atob(response.data.contenido)
            var byteNumbers = new Array(byteCharacters.length)
            for (var i = 0; i < byteCharacters.length; i++) {
              byteNumbers[i] = byteCharacters.charCodeAt(i)
            }
            var byteArray = new Uint8Array(byteNumbers)
            var blob = new Blob([byteArray], { type: 'application/pdf' })
            window.navigator.msSaveOrOpenBlob(blob, response.data.nombre + '.pdf')
          } else {
            var link = document.createElement('a')
            var uri = 'data:application/pdf;base64,' + response.data.contenido
            link.download = response.data.nombre
            link.href = uri
            document.body.appendChild(link)
            link.click()
            document.body.removeChild(link)
          }
        } else {
          console.warn('No se ha podido cargar el contenido')
        }
        setIsLoadingPdf(false)
      })
      .catch(() => {
        console.log('Error downloading invoice')
        setIsLoadingPdf(false)
      })
  }

  const executePendingAction = () => {
    if (pendingAction) {
      pendingAction()
      setPendingAction(null)
    }
  }

  useEffect(() => {
    if (isTwoFactorAuthenticated) {
      executePendingAction()
    }
  }, [isTwoFactorAuthenticated])

  const handleButtonClick = async (isPaymentButton) => {
    const downloadAction = async () => {
      if (isPaymentButton) {
        const config = { params: { order_number: invoice.num_pedido } }
        if (role === 'ATR' && clientInfo['nombre']) {
          config.params['client'] = clientInfo['dni']
        }
        const result = await getInvoicePaymentPdf(config)
        downloadPDF(result.data.document, result.data.name)
      } else {
        downloadInvoice()
      }
    }

    if (isTwoFactorAuthenticated) {
      downloadAction()
    } else {
      setPendingAction(() => downloadAction)
      handleOpenUnblurDialog()
    }
  }

  useEffect(() => {
    if (invoice) {
      setOpenDialog(true)
    }
  }, [invoice])

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      open={openDialog}
      maxWidth={'md'}
    >
      <DialogTitle id="customized-dialog-title" onClose={handleClose} importe={invoice?.importe}>
        {formatMessage({
          id: 'pages.invoice.details.title',
          defaultMessage: 'Datos de la factura',
        })}
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={2}>
          <Grid item md={6}>
            <Box flex justifyContent={'space-between'}>
              <Typography variant={'body1'}>
                {formatMessage({ id: `pages.invoice.details.body.numero_factura.label` })}
              </Typography>
              <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                {invoice?.factura}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box flex justifyContent={'space-between'}>
              <Typography variant={'body1'}>
                {formatMessage({ id: `pages.invoice.details.body.contrato.label` })}
              </Typography>
              {isTwoFactorAuthenticated ? (
                <Link to={`/contracts?contract=${invoice?.contrato}`} style={{ color: '#004165' }}>
                  <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                    {invoice?.contrato}
                  </Typography>
                </Link>
              ) : (
                <Box
                  style={{ cursor: 'pointer' }}
                  display="flex"
                  alignItems="center"
                  flexWrap="nowrap"
                  onClick={handleOpenUnblurDialog}
                >
                  <Typography variant={'body1'} style={{ fontWeight: 'bold', marginRight: '8px' }}>
                    {invoice?.contrato}
                  </Typography>
                  <Icon fontSize="inherit">visibility_off</Icon>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box flex justifyContent={'space-between'}>
              <Typography variant={'body1'}>
                {formatMessage({ id: `pages.invoice.details.body.fecha_factura.label` })}
              </Typography>
              <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                {invoice?.fecha_factura}
              </Typography>
            </Box>
          </Grid>
          <Grid item md={6}>
            <Box flex justifyContent={'space-between'}>
              <Typography variant={'body1'}>
                {formatMessage({ id: `pages.invoice.details.body.fecha_vencimiento.label` })}
              </Typography>
              <Typography variant={'body1'} style={{ fontWeight: 'bold' }}>
                {invoice?.fecha_vencimiento}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {invoice?.tipo_documento !== 'fee' && (
          <ThemeButton
            color="primary"
            onClick={() => handleButtonClick(false)}
            style={{ minWidth: '130px', margin: '10px 0' }}
            loading={isLoadingPdf && isTwoFactorAuthenticated}
          >
            {formatMessage({ id: `pages.invoice.details.downloadPdf.button` })}
          </ThemeButton>
        )}
        {showDownloadPayment && invoice?.num_pedido?.length ? (
          <ThemeButton
            color="default"
            onClick={() => handleButtonClick(true)}
            style={{ minWidth: '130px', margin: '10px 0' }}
            loading={isLoadingPdf && isTwoFactorAuthenticated}
          >
            {formatMessage({ id: `pages.invoice.details.downloadPayment.button` })}
          </ThemeButton>
        ) : null}
      </DialogActions>
    </Dialog>
  )
}
