export const invoiceDataPreparation = (data, { intl }, columns = []) => {
  return data.map((item) => {
    const formattedData = {}
    columns.forEach((column) => {
      if (column.field === 'importe' && item[column.field]) {
        formattedData[column.field] = item[column.field]
      }
    })
    return {
      ...item,
      ...formattedData,
    }
  })
}
