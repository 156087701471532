import React from 'react'
import { selectClient } from '../../slices/searchUser.slice'
import { useSelector } from 'react-redux'
import { Alert, AlertTitle } from '@material-ui/lab'
import { Typography } from '@material-ui/core'
import { useIntl } from 'react-intl'
import searchInfoStyles from './search-info.styles'

export const SearchInfoComponent = () => {
  const classes = searchInfoStyles()
  const clientInfo = useSelector(selectClient)
  const { formatMessage } = useIntl()

  return (
    <div className={classes.container}>
      {clientInfo && clientInfo.nombre ? (
        <Alert severity="info" variant="outlined" className={classes.root}>
          <AlertTitle variant="h5" component="h2">
            {clientInfo.nombre + ' ' + clientInfo.apellidos}
          </AlertTitle>
          <Typography>
            {formatMessage({ id: 'pages.profile.user.fields.id' })} {clientInfo.dni}
          </Typography>
        </Alert>
      ) : null}
    </div>
  )
}
