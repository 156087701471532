export const loginStyles = (theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    margin: 'auto',
  },
  leftWrapper: {
    backgroundColor: '#FFFFFF',
    maxWidth: '600px',
    boxShadow: '0px 5px 7px -1px rgba(0,0,0,0.25)',
    flex: 1,
  },
  container: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  panel: {
    margin: '20px 0',
    padding: '30px 60px',
    borderRadius: 4,
    backgroundColor: '#FFFFFF',
    [theme.breakpoints.down('xs')]: {
      padding: '20px',
    },
  },
  signupWrapper: {
    textAlign: 'center',
  },
  signupLink: {
    textDecoration: 'none',
    margin: '0 4px',
  },
  signupMessage: {
    color: theme.palette.primary.main,
    fontFamily: 'FSEmeric-Medium',
    textDecoration: 'underline',
  },
})
