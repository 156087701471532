import React from 'react'
import { Button, Tooltip } from '@material-ui/core'
import SearchIcon from '@material-ui/icons/Search'

export const DetailsButton = ({ handleOpenDetails, formatMessage, rowData }) => {
  return (
    <Tooltip
      title={
        <span style={{ fontSize: '13px', margin: '0 !important' }}>
          {formatMessage({ id: `pages.invoice.table.header.actions.button` })}
        </span>
      }
    >
      <span>
        <Button
          variant="outlined"
          color="primary"
          labelM="small"
          style={{
            margin: '0 !important',
            padding: '6px',
            borderRadius: '100%',
            minWidth: 'unset',
          }}
          onClick={() => handleOpenDetails('', rowData)}
        >
          <SearchIcon />
        </Button>
      </span>
    </Tooltip>
  )
}

export const InvoicesTableConstants = (handleOpenDetails, formatMessage) => [
  {
    field: 'actions',
    sorting: false,
    align: 'right',
    width: 40,
    render: (rowData) => (
      <DetailsButton
        handleOpenDetails={handleOpenDetails}
        formatMessage={formatMessage}
        rowData={rowData}
      />
    ),
  },
  { field: 'tipo_documento', sorting: true, align: 'left', width: 40 },
  {
    field: 'fecha_factura',
    sorting: true,
    align: 'left',
    width: 60,
    defaultSort: 'desc',
    customSort: (a, b) => {
      // Parse dd/mm/yyyy to Date objects
      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('/').map(Number)
        return new Date(year, month - 1, day) // Month is zero-indexed
      }
      return parseDate(a.fecha_factura) - parseDate(b.fecha_factura)
    },
  },
  { field: 'fecha_vencimiento', sorting: true, align: 'left', width: 60 },
  { field: 'factura', sorting: true, align: 'left', width: 80 },
  {
    field: 'importe',
    sorting: true,
    align: 'right',
    width: 80,
    render: (rowData) =>
      new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
        rowData.importe
      ),
    cellStyle: { fontWeight: 'bold', color: '#004165' },
  },
  {
    field: 'importe_pendiente',
    sorting: true,
    align: 'right',
    width: 120,
    render: (rowData) =>
      new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
        rowData.importe_pendiente
      ),
    cellStyle: { fontWeight: 'bold', color: '#004165' },
  },
  { field: 'estado_pago', sorting: true, align: 'left', width: 80 },
  { field: 'num_pedido', sorting: true, align: 'left', width: 80 },
  { field: 'fecha_pago', sorting: true, align: 'left', width: 60 },
]

export const PendingInvoicesTableConstants = (handleOpenDetails, formatMessage) => [
  {
    field: 'actions',
    sorting: false,
    align: 'right',
    width: 40,
    render: (rowData) => (
      <DetailsButton
        handleOpenDetails={handleOpenDetails}
        formatMessage={formatMessage}
        rowData={rowData}
      />
    ),
  },
  { field: 'tipo_documento', sorting: true, align: 'left', width: 40 },
  {
    field: 'fecha_factura',
    sorting: true,
    align: 'left',
    width: 60,
    defaultSort: 'desc',
    customSort: (a, b) => {
      // Parse dd/mm/yyyy to Date objects
      const parseDate = (dateStr) => {
        const [day, month, year] = dateStr.split('/').map(Number)
        return new Date(year, month - 1, day) // Month is zero-indexed
      }
      return parseDate(a.fecha_factura) - parseDate(b.fecha_factura)
    },
  },
  { field: 'fecha_vencimiento', sorting: true, align: 'left', width: 60 },
  { field: 'factura', sorting: true, align: 'left', width: 80 },
  {
    field: 'importe_pendiente',
    sorting: true,
    align: 'right',
    width: 120,
    render: (rowData) =>
      new Intl.NumberFormat('es-ES', { style: 'currency', currency: 'EUR' }).format(
        rowData.importe_pendiente
      ),
    cellStyle: { fontWeight: 'bold', color: '#004165' },
  },
  { field: 'estado_pago', sorting: true, align: 'left', width: 80 },
  { field: 'num_pedido', sorting: true, align: 'left', width: 80 },
  { field: 'fecha_pago', sorting: true, align: 'left', width: 60 },
]
