import React from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { Box, Typography, withStyles } from '@material-ui/core'
import { ThemeButton } from '../../../ui'
import { processStatusStyles } from './process-status.styles'
import processStatusOK from '../../../resources/images/process-status-OK.jpeg'
import processStatusKO from '../../../resources/images/process-status-KO.png'

export const ProcessStatusComponent = withStyles(processStatusStyles)(({ classes }) => {
  const { formatMessage } = useIntl()
  const { status } = useParams()
  const history = useHistory()

  const isSuccess = status?.toLowerCase() === 'ok'

  const getDynamicMessage = (key) =>
    formatMessage({ id: `pages.process-status.${key}.${isSuccess ? 'success' : 'error'}` })

  const getDynamicImage = () => (isSuccess ? processStatusOK : processStatusKO)

  const navigateToModule = (path) => {
    history.push('/' + path)
  }

  return (
    <Box className={classes.root}>
      <Typography className={classes.title} variant="h4" color="primary" paragraph>
        {getDynamicMessage('title')}
      </Typography>
      <Typography variant="h5" paragraph>
        {getDynamicMessage('subTitle')}
      </Typography>
      <Box className={classes.imageContainer}>
        <img
          src={getDynamicImage()}
          alt={isSuccess ? 'Success' : 'Error'}
          className={classes.dynamicImage}
        />
      </Box>
      <ThemeButton
        style={{ width: '16em' }}
        color="primary"
        onClick={() => navigateToModule('invoices')}
      >
        {formatMessage({ id: 'pages.process-status.btn' })}
      </ThemeButton>
    </Box>
  )
})
