import { instances } from '../instances'

const {
  publicWebInstance,
  privateWebInstance,
  publicRegistroInstance,
  privateRegistroInstance,
  privateDocumentumInstance,
} = instances

export const requests = {
  // URL 1: web-api
  confirm: (data) => publicWebInstance.post('confirm', data),
  confirmRegister: (data) => publicWebInstance.post('confirm-register', data),
  contracts: (config) => privateWebInstance.get('contracts', config),
  infoClient: (queryParams = '', config) =>
    privateWebInstance.get('info_client' + queryParams, config),
  invoices: (config) => privateWebInstance.get('invoices', config),
  payinvoices: (config) => privateWebInstance.post('payment', config),
  login: (data) => publicWebInstance.post('login', data),
  obfuscation: (data) => privateWebInstance.post('obfuscation', data),
  obfuscationRemoval: (queryParams = '', data) =>
    privateWebInstance.get('obfuscation-removal' + queryParams, data),
  refresh: (data) => privateWebInstance.post('refresh', data),
  paymentReceipt: (data) => privateWebInstance.get('payment/receipt', data),

  // URL 2: registro-api
  register: (data) => publicRegistroInstance.post('register', data),
  deregister: (config) => privateRegistroInstance.delete('deregister', config),
  recover: (data) => publicRegistroInstance.post('recover', data),
  update: (data) => privateRegistroInstance.post('update', data),

  // URL 3: documentum-api
  invoiceDoc: (config) => privateDocumentumInstance.get('invoice_doc', config),
}
