import React from 'react'
import { Container, withStyles } from '@material-ui/core'

import { ProcessStatus } from '../../../features/process-status'
import { processStatusStyles } from './process-status.styles'

export const ProcessStatusPage = withStyles(processStatusStyles)(({ classes }) => {
  return (
    <div className={classes.root}>
      <Container className={classes.container} maxWidth="md">
        <ProcessStatus />
      </Container>
    </div>
  )
})
