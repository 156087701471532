import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Typography } from '@material-ui/core'

import { verifyPwd } from '../../../../core/utils'
import { PasswordInput, ThemeButton } from '../../../../ui'
import { feedback } from '../../../../core/feedback'
import { useProfileCalls } from '../../hooks/profile-calls.hook'

export const ChangePwdFormComponent = ({ close }) => {
  const { formatMessage } = useIntl()
  const { changePassword } = useProfileCalls()

  const [formState, setFormState] = useState({})
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [isValidPassword, setIsValidPassword] = useState(false)

  useEffect(() => {
    const { isValid, errorMssg: newError } = verifyPwd(
      formState['newPassword'],
      formState['repitNewPassword'],
      formatMessage
    )
    if (formState['newPassword'] && formState['repitNewPassword']) {
      setIsValidPassword(isValid)
      setErrorMssg(newError)
    }
  }, [formState['newPassword'], formState['repitNewPassword']]) // eslint-disable-line

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleSubmitForm = async (event) => {
    event.preventDefault()
    setErrorMssg('')
    setLoading(true)

    if (!formState['currentPassword']) {
      feedback(
        'error',
        formatMessage({
          id: 'pages.changePwdForm.error.currentPassword.required',
          defaultMessage: 'Debe introducir la contraseña actual',
        })
      )
      setLoading(false)
      return
    }

    if (isValidPassword) {
      const payload = {
        old_password: formState['currentPassword'],
        new_password: formState['newPassword'],
      }
      await changePassword(payload).then(({ data }) => {
        feedback(
          'success',
          formatMessage({
            id: 'pages.changePwdForm.success.saved',
            defaultMessage: 'Datos guardados',
          })
        )
        close()
      })
    } else {
      feedback(
        'error',
        formatMessage({
          id: 'pages.changePwdForm.error.invalidPassword',
          defaultMessage: 'Contraseña inválida',
        })
      )
    }
    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Box mb={1}>
        <PasswordInput
          value={formState['currentPassword']}
          name="currentPassword"
          // title={formatMessage({ id: 'pages.pwdRecovery.form.password.label' })}
          title={formatMessage({
            id: 'pages.changePwdForm.field.currentPassword.labelPlaceHolder',
            defaultMessage: 'Contraseña actual',
          })}
          placeholder={formatMessage({
            id: 'pages.changePwdForm.field.currentPassword.labelPlaceHolder',
            defaultMessage: 'Contraseña actual',
          })}
          onChange={handleChangeForm}
          required
          style={{ marginBottom: 40 }}
        />
        <PasswordInput
          value={formState['newPassword']}
          name="newPassword"
          title={formatMessage({
            id: 'pages.changePwdForm.field.newPassword.labelPlaceHolder',
            defaultMessage: 'Nueva contraseña',
          })}
          placeholder={formatMessage({
            id: 'pages.changePwdForm.field.newPassword.labelPlaceHolder',
            defaultMessage: 'Nueva contraseña',
          })}
          onChange={handleChangeForm}
          required
          style={{ marginBottom: 16 }}
        />
        <PasswordInput
          value={formState['repitNewPassword']}
          name="repitNewPassword"
          title={formatMessage({ id: 'pages.pwdRecovery.form.repitPassword.label' })}
          placeholder={formatMessage({
            id: 'pages.pwdRecovery.form.repitPassword.placeholder',
          })}
          onChange={handleChangeForm}
          required
        />
      </Box>
      {errorMssg && (
        <Box mb={1} bgcolor="grey">
          <Typography variant="body2" color="error">
            {errorMssg}
          </Typography>
        </Box>
      )}
      <Box display="flex" justifyContent="center">
        <Box display="flex" justifyContent={'center'} gridGap={'16px'} width={220}>
          <ThemeButton type="submit" fullWidth loading={loading}>
            {formatMessage({ id: 'global.accept', defaultMessage: 'Aceptar' })}
          </ThemeButton>
          <ThemeButton color="default" fullWidth onClick={close}>
            {formatMessage({ id: 'global.cancel', defaultMessage: 'Cancelar' })}
          </ThemeButton>
        </Box>
      </Box>
    </form>
  )
}
