import React, { useContext, useState } from 'react'
import { useIntl } from 'react-intl'
import { Box, Grid, makeStyles } from '@material-ui/core'

import { PasswordInput, TextInput, ThemeButton } from '../../../ui'
import { PwdRecoveryMessageComponent } from './pwd-recovery'
import { useGlobalActions } from '../../global'
import { loginFormStyles } from './login-form.styles'
import { useLoginCalls } from '../hooks/login-calls.hook'
import { useHistory } from 'react-router-dom'
import { ModalContext } from '../../../ui/modal/contexts'
import { ResetPasswordDialogComponent } from './reset-password-dialog'
import { ConfirmSignUpDialog } from '../../signup/components/confirm-modal'
import { Alert } from '@material-ui/lab'

export const LoginFormComponent = () => {
  const classes = makeStyles(loginFormStyles)()
  const { formatMessage } = useIntl()
  const { loginUser } = useGlobalActions()
  const { resendValidation } = useLoginCalls()
  const history = useHistory()

  const [formState, setFormState] = useState({
    identifier_type: 'NIF',
    username: '',
    password: '',
  })
  const [loading, setLoading] = useState(false)
  const [errorMssg, setErrorMssg] = useState('')
  const [validatedMailForRecovery, setValidatedMailForRecovery] = useState(false)
  const {
    actions: { open },
  } = useContext(ModalContext)

  const handleChangeForm = (event) =>
    setFormState({ ...formState, [event.target.name]: event.target.value })

  const handleOpenResetPasswordDialog = (email) =>
    open({
      Component: ResetPasswordDialogComponent,
      props: {
        close: () => {}, // Se maneja el cierre desde el diálogo directamente
      },
      data: {
        username: formState['username'],
        email,
      },
      type: 'responsive',
    })

  const handleOpenConfirmUserDialog = (email) =>
    open({
      Component: ConfirmSignUpDialog,
      props: {
        close: () => {}, // Se maneja el cierre desde el diálogo directamente
      },
      data: {
        username: formState['username'],
        password: formState['password'],
        email,
      },
      type: 'responsive',
    })

  const validateForm = () => {
    if (!formState['username']) {
      setErrorMssg(
        formatMessage({
          id: 'pages.login.error.username.required',
          defaultMessage: 'Identificador requerido',
        })
      )
      return false
    }
    if (!formState['password']) {
      setErrorMssg(
        formatMessage({
          id: 'pages.login.error.password.required',
          defaultMessage: 'Contraseña requerida',
        })
      )
      return false
    }
    return true
  }

  const handleSubmitForm = (event) => {
    event.preventDefault()
    if (!validateForm()) return
    setLoading(true)
    setValidatedMailForRecovery(formState.username)
    loginUser(formState)
      .then(() => {
        setLoading(false)
        history.push('/invoices')
      })
      .catch((reason) => {
        if (reason?.response?.status === 401) {
          setErrorMssg(
            formatMessage({
              id: 'pages.login.error.invalid.credentials',
              defaultMessage: 'El usuario o la contraseña son incorrectos',
            })
          )
        } else if (reason?.response?.status === 409) {
          handleOpenResetPasswordDialog(reason?.response?.data?.email ?? '')
        } else if (reason?.response?.status === 417) {
          handleOpenConfirmUserDialog(reason?.response?.data?.email ?? '')
        } else {
          setErrorMssg(
            formatMessage({
              id: 'pages.login.error.invalid.credentials',
              defaultMessage: 'El usuario o la contraseña son incorrectos',
            })
          )
        }
        setLoading(false)
      })
  }

  return (
    <form onSubmit={handleSubmitForm}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
          <TextInput
            type="text"
            value={formState['username']}
            name="username"
            title={formatMessage({ id: 'pages.login.form.field.username.title' })}
            placeholder={formatMessage({ id: 'pages.login.form.field.username.placeholder' })}
            onChange={handleChangeForm}
            required
          />
        </Grid>
      </Grid>
      <Box mt={2}>
        <PasswordInput
          required
          value={formState['password']}
          name="password"
          title={formatMessage({ id: 'pages.login.form.field.password.title' })}
          placeholder={formatMessage({ id: 'pages.login.form.field.password.placeholder' })}
          onChange={handleChangeForm}
        />
      </Box>
      <PwdRecoveryMessageComponent />
      {errorMssg && (
        <Box mt={1} display={'flex'} justifyContent={'center'}>
          <Alert severity={'error'}>{errorMssg}</Alert>
        </Box>
      )}
      <ThemeButton type="submit" fullWidth loading={loading}>
        {formatMessage({ id: 'pages.login.button.label' })}
      </ThemeButton>
    </form>
  )
}
