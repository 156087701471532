import React, { useEffect, useState } from 'react'
import { useIntl } from 'react-intl'
import {
  Box,
  DialogContent,
  DialogTitle,
  Icon,
  Typography,
  withStyles,
  Dialog,
} from '@material-ui/core'
import { toast } from 'react-toastify'

import { ResetPasswordDialogStyles } from './reset-password-dialog.styles'
import { verifyPwd } from '../../../../core/utils'
import { CodeInput, PasswordInput, ThemeButton } from '../../../../ui'
import { Alert } from '@material-ui/lab'
import { useLoginCalls } from '../../hooks/login-calls.hook'
import { useGlobalActions } from '../../../global'

export const ResetPasswordDialogComponent = withStyles(ResetPasswordDialogStyles)(
  ({ username, email, close }) => {
    const { formatMessage } = useIntl()

    const [formState, setFormState] = useState({})
    const [loading, setLoading] = useState(false)
    const [errorMssg, setErrorMssg] = useState('')
    const [isValidPassword, setIsValidPassword] = useState(false)
    const { confirmRecovery } = useLoginCalls()
    const [openDialog, setOpenDialog] = useState(true)

    const handleClose = () => {
      close()
      setOpenDialog(false)
    }

    useEffect(() => {
      if (!formState['password'] || !formState['repitPassword']) return
      const { isValid, errorMssg } = verifyPwd(
        formState['password'],
        formState['repitPassword'],
        formatMessage
      )
      setIsValidPassword(isValid)
      setErrorMssg(errorMssg)
    }, [formState]) // eslint-disable-line

    const handleChangeForm = (event) =>
      setFormState({ ...formState, [event.target.name]: event.target.value })

    const handleSubmitForm = (event) => {
      event.preventDefault()
      if (formState['code']?.length !== 6) {
        setErrorMssg(
          formatMessage({
            id: 'pages.login.resetPwd.code.invalid',
            defaultMessage: 'El código debe tener 6 dígitos',
          })
        )
        return
      }
      setErrorMssg('')
      setLoading(true)
      if (isValidPassword) {
        const payload = {
          code: formState['code'],
          username: username,
          new_password: formState['password'],
        }
        confirmRecovery(payload)
          .then(({ data }) => {
            toast.success(
              <>
                <Icon>check_circle</Icon>
                <Typography
                  variant="body1"
                  display="inline"
                  style={{ verticalAlign: 'super', marginLeft: 8 }}
                >
                  {formatMessage({
                    id: 'pages.login.resetPwd.success',
                    defaultMessage: 'La contraseña se ha cambiado con éxito',
                  })}
                </Typography>
              </>
            )
            close()
          })
          .catch(() => {
            console.error('Error requesting change password')
            setErrorMssg(
              formatMessage({
                id: 'pages.login.resetPwd.code.expired',
                defaultMessage:
                  'El código es inválido o ha expirado, por favor solicita un nuevo código',
              })
            )
          })
      } else {
        setErrorMssg(
          formatMessage({
            id: 'pages.login.resetPwd.password.invalid',
            defaultMessage: 'La contraseña no cumple con los requisitos',
          })
        )
      }
      setLoading(false)
    }

    return (
      <Dialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={openDialog}
        maxWidth={'md'}
      >
        <DialogTitle>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="h4" color="primary">
              {formatMessage({ id: 'pages.pwdRecovery.title' })}
            </Typography>
          </Box>
          <Box display="flex" alignItems="center" justifyContent="center">
            <Typography variant="subtitle1" color="red">
              {formatMessage({ id: 'pages.login.resetPwd.description' })}
            </Typography>
          </Box>
        </DialogTitle>
        <DialogContent>
          <form onSubmit={handleSubmitForm}>
            <Box mb={4} display={'flex'} gridGap={'16px'} flexDirection={'column'}>
              <CodeInput
                label={`${formatMessage({
                  id: 'pages.signup.confirmDialog.text',
                  defaultMessage: 'Código',
                })} ${email ?? ''}`}
                name="code"
                onChange={handleChangeForm}
              />
              <PasswordInput
                value={formState['password']}
                name="password"
                title={formatMessage({ id: 'pages.pwdRecovery.form.password.label' })}
                placeholder={formatMessage({ id: 'pages.pwdRecovery.form.password.placeholder' })}
                onChange={handleChangeForm}
                required
              />
              <Typography variant="body2" color="textSecondary" style={{ whiteSpace: 'pre-line' }}>
                {formatMessage({ id: 'pages.pwdRecovery.extra' })}
              </Typography>
              <PasswordInput
                value={formState['repitPassword']}
                name="repitPassword"
                title={formatMessage({ id: 'pages.pwdRecovery.form.repitPassword.label' })}
                placeholder={formatMessage({
                  id: 'pages.pwdRecovery.form.repitPassword.placeholder',
                })}
                onChange={handleChangeForm}
                required
              />
            </Box>
            {errorMssg && <Alert severity={'error'}>{errorMssg}</Alert>}
            <ThemeButton type="submit" fullWidth loading={loading}>
              {formatMessage({ id: 'pages.pwdRecovery.form.button.label' })}
            </ThemeButton>
          </form>
        </DialogContent>
      </Dialog>
    )
  }
)
